import { lazy } from 'react'

// ** Document title
const TemplateTitle = '%s - Florinz - Marketing Cloud System'

// ** Default Route
const DefaultRoute = '/qr/:uuid/'

// ** Merge Routes
const Routes = [
  {
    path: '/qr/:uuid',
    component: lazy(() => import('../../views/Home'))
  },
  {
    path: '/referral/:uuid',
    component: lazy(() => import('../../views/ReferralLink'))
  },
  {
    path: '/register-and-win/:uuid',
    component: lazy(() => import('../../views/registerAndWin/index'))
  },
  {
    path: '/apply-coupons/:uuid',
    component: lazy(() => import('../../views/components/deliveryPickup/components/applyCoupons'))
  },
  {
    path: '/evaluate/:uuid/:form',
    component: lazy(() => import('../../views/Evaluation'))
  },
  {
    path: '/reference/:code',
    component: lazy(() => import('../../views/ReferenceLink'))
  },
  {
    path: '/error',
    component: lazy(() => import('../../views/Error')),
    layout: 'BlankLayout'
  },
  {
    path: '/deactivated/:uuid',
    component: lazy(() => import('../../views/RedirectPage'))
  },
  {
    path: `/cart/:uuid`,
    component: lazy(() => import('../../views/menu/Cart'))
  },
  {
    path: '/menu/:uuid',
    component: lazy(() => import('../../views/menu/Menu'))
  },
  {
    path: '/items/:uuid/:id/:name',
    component: lazy(() => import('../../views/menu/Item'))
  },
  {
    path: '/points-shop/:uuid',
    component: lazy(() => import('../../views/customerProfile/components/pointsShop/components/pointsShopLevels'))
  },
  {
    path: '/prize-shop/:uuid',
    component: lazy(() => import('../../views/customerProfile/components/pointsShop/components/prizeShop'))
  },
  {
    path: '/items/:uuid/:name',
    component: lazy(() => import('../../views/menu/Item'))
  },
  {
    path: '/prize/:uuid',
    component: lazy(() => import('../../views/PrizeWheel'))
  },
  {
    path: '/my-rewards/:uuid',
    component: lazy(() => import('../../views/customerProfile/index'))
  },
  {
    path: '/checkout/:uuid',
    component: lazy(() => import('../../views/components/deliveryPickup/components/checkout'))
  },
  {
    path: '/register/:uuid',
    component: lazy(() => import('../../views/components/registration/index'))
  },
  {
    path: '/edit-profile/:uuid',
    component: lazy(() => import('../../views/customerProfile/components/editProfile/index'))
  },
  {
    path: '/payment/:uuid',
    component: lazy(() => import('../../views/components/payment/orderPayment'))
  },
  {
    path: '/confirm-order/:uuid',
    component: lazy(() => import('../../views/components/deliveryPickup/components/OrderConfirmation'))
  },
  {
    path: '/order-type/:uuid',
    component: lazy(() => import('../../views/components/deliveryPickup/DeliveryPickup'))
  },
  {
    path: '/order-data/:uuid',
    component: lazy(() => import('../../views/components/deliveryPickup/components/OrderData'))
  },
  {
    path: '/payment-failed/:uuid',
    component: lazy(() => import('../../views/components/payment/PaymentFailed'))
  }
]

export { DefaultRoute, TemplateTitle, Routes }
