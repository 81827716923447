import React from "react"

const Context = React.createContext()

class PopUpProviderWrapper extends React.Component {
  state = {
    show: false,
    activePopUp: null,
    firstLaunch: false
  }

  render() {
    const { children } = this.props
    return (
      <Context.Provider
        value={{
          state: this.state,
          onVisibilityChange: visibility => {
            this.setState({
              show: visibility?.show,
              activePopUp: visibility?.activePopUp,
              firstLaunch: true
            })
          }
        }}
      >
        {children}
      </Context.Provider>
    )
  }
}

export { PopUpProviderWrapper, Context as PopUpContext }
